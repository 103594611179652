body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-swiper-pagination-bullet-active {
  opacity: inherit !important;
  background: #189AB4 !important;
}

#curator-feed-default-feed-layout .crt-grid {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important; /* 3 posts per row */
  gap: 16px !important; /* Adjust spacing between posts */
  align-items: start !important;
  justify-content: center !important;
}

#curator-feed-default-feed-layout .crt-grid > div {
  width: 100% !important; /* Ensure proper scaling */
}
